import React, { useState } from 'react'
import { Notifications, NotificationsOff } from '@mui/icons-material'
import './style.scss'
import PopUpConfirm from '../Table/components/PopupConfirm'
import { useAuth } from '../../Provider/AuthProvider'
import { handleNotificationClient } from '../../services/clients'
import { useLoading } from '../../Provider/LoadingProvider'
const LockNotification = () => {
  const { user } = useAuth()
  const { startLoading, stopLoading } = useLoading()
  const [showPopup, setShowPopup] = useState(false)
  const toggleNotification = () => {
    setShowPopup(true)
  }
  const handleAccept = async () => {
    try {
      startLoading()
      setShowPopup(false)
      await handleNotificationClient(user?.clientId as string, !user?.client?.notification)
      // setShowNotification(!showNotification)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const handleReject = () => {
    setShowPopup(false)
  }

  return (
    <>
      <div className="lock-container" onClick={toggleNotification}>
        {user?.client?.notification ? (
          <Notifications className="lock-icon" />
        ) : (
          <NotificationsOff className="lock-icon" />
        )}
      </div>
      <PopUpConfirm
        isOpen={showPopup}
        onClose={() => setShowPopup(false)}
        title={'Notificaciones'}
        description={`Se ${
          user?.client?.notification ? 'desconectarán' : 'conectarán'
        } las notificaciones de visitantes para todos los residentes y sus delegados ?`}
        acceptText="Proceder"
        rejectText="Cancelar"
        handleAccept={handleAccept}
        handleReject={handleReject}
      />
    </>
  )
}

export default LockNotification
