// ClientDetailsPage.tsx
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useAuth } from '../../Provider/AuthProvider'
import ButtonSection from '../../components/ButtonSelection'
import DynamicForm, { FieldTypeEnum, FormField } from '../../components/Form/DynamicForm'
import DelegatedResident from '../../components/Tables/DelegatedResident'
import { residentFormConfig } from '../../components/Tables/Resident/Columns'
import WorkerTable from '../../components/Tables/Worker'
import StatementResident from '../../components/Tables/StatementResident'
import { getResident, updateResident } from '../../services/resident'
import { Resident, ResidentCreate } from '../../types/resident'
import { cleanObject } from '../../utils/utils'
import './style.scss'
import { useLoading } from '../../Provider/LoadingProvider'
import { listDestinationsAll } from '../../services/destination'
import { Destination } from '../../types/Destination'
import { LightTooltip } from '../../components/tooltips/text'
import { Fade } from '@mui/material'
import { ArrowLeft } from '@mui/icons-material'
const ResidentDetailsPage = () => {
  const { id } = useParams<{ id: string }>()
  const { user } = useAuth()
  const { startLoading, stopLoading } = useLoading()
  const [residentDetail, setResidentDetail] = useState<Resident>()
  const [destinations, setDestinations] = useState<Destination[]>([])
  const navigate = useNavigate()

  const fetchData = async () => {
    try {
      const details = await getResident(id as string)
      setResidentDetail(details)
    } catch (error) {
      console.error('Error fetching client details:', error)
    }
  }

  const handleSubmit = async (formData: ResidentCreate) => {
    try {
      startLoading()
      const dataToSend = {
        destinationCode: formData.destinationCode,
        name: formData.name,
        lastName: formData.lastName,
        email: formData.email,
        delegatedQuantity: formData.delegatedQuantity,
        phone1: formData.phone1,
        phone2: formData.phone2,
        destinationId: formData.destinationId as string,
        clientId: user?.clientId as string
      }
      const clearData = cleanObject(dataToSend)

      await updateResident(id as string, clearData as ResidentCreate)
      await fetchData()
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const buttonsInfo = [
    {
      text: 'Residentes delegados',
      component: (
        <DelegatedResident
          maxAmount={residentDetail?.delegatedQuantity || 0}
          id={id as string}
        />
      )
    },
    {
      text: 'Trabajadores',
      component: <WorkerTable id={id as string} />
    },
    ...(residentDetail
      ? [
          {
            text: 'Cuenta',
            styleContainer: { width: '900px' },
            component: (
              <StatementResident
                id={id as string}
                clientId={user?.clientId as string}
                residentDetail={residentDetail}
              />
            )
          }
        ]
      : [])
  ]

  const fullResidentFormConfig: FormField[] = [
    ...residentFormConfig,
    {
      type: FieldTypeEnum.select,
      label: 'Tipo de Destino',
      name: 'destinationId',
      options: destinations.map(e => ({
        value: e.id,
        label: e.name
      }))
    }
  ]
  const getDestination = async () => {
    try {
      const destinations = await listDestinationsAll()
      setDestinations(destinations)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (id) {
      getDestination()
      fetchData()
    }
  }, [id])
  return (
    <div className="residentDetails">
      <div className="title">
        <LightTooltip
          title="REGRESAR"
          followCursor
          TransitionComponent={Fade}
          onClick={() => navigate('/resident')}
        >
          <ArrowLeft />
        </LightTooltip>
        <p>Detalles de residente</p>
      </div>
      {residentDetail && (
        <DynamicForm
          initialValues={residentDetail}
          formConfig={fullResidentFormConfig}
          onSubmit={handleSubmit}
        />
      )}
      <div className="title">
        <p>Gestión de residente</p>
      </div>
      <div className="containerButton">
        {id && <ButtonSection buttonsInfo={buttonsInfo} />}
      </div>
    </div>
  )
}

export default ResidentDetailsPage
