import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './style.scss'
import { useAuth } from '../../Provider/AuthProvider'
import { io } from 'socket.io-client'
import { GeneralPayment } from '../../types/Payments'
import ReactECharts from 'echarts-for-react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton } from '@mui/material'
import { getGeneralPaymentByClientId, getsearchPayments } from '../../services/Payments'
import { useLoading } from '../../Provider/LoadingProvider'
import Modal from '../../components/GeneralComponents/Modal'

import ExportToExcel from './generateXML'
import PhotoTooltip from '../../components/tooltips/img'
import PaymentsDetails from '../../components/Tables/PaymentsDetails'
import moment from 'moment'
import DateRangePicker from '../../components/Form/RangePicker3'
import { MultiSelectANTD } from '../../components/Form/multiSelet'
import { Search } from '@mui/icons-material'
import { useDebounce } from '../../hooks/useDebounce'

export interface typesearch {
  date?: string[]
  text?: string
  order?: string
  status?: string[]
}

const Accreditations = () => {
  const { user } = useAuth()
  const { startLoading, stopLoading } = useLoading()
  const { id } = useParams<{ id: string }>()
  const [idSearch, setIdSearch] = useState<string>()
  const [GeneralData, setGeneralData] = useState<GeneralPayment>()
  const [selected, setSelected] = useState<{ id: string; action: string }>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [search, setSearch] = useState<typesearch>()
  const debounceValue = useDebounce(search, 500)

  useEffect(() => {
    if (debounceValue) handlesearchPayments(debounceValue)
  }, [debounceValue])

  useEffect(() => {
    const socket = io(
      process.env.REACT_APP_API_URL_WHATS || ('https://whats.ipass.com.gt' as string)
    )
    if (user?.clientId) {
      getGeneralData(user?.clientId)

      socket.on(`payment-${user?.clientId}`, (message: string) => {
        getGeneralData(message)
      })
    }

    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    const dataId = user?.role === 'corporate' ? (id as string) : user?.clientId
    setIdSearch(dataId)
  }, [id, idSearch, setIdSearch, user])

  const getGeneralData = async (id: string) => {
    try {
      startLoading()
      if (debounceValue) {
        await handlesearchPayments(debounceValue)
      } else {
        const data = await getGeneralPaymentByClientId(id)
        setGeneralData(data)
      }
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  if (!GeneralData || !user?.clientId)
    return (
      <div className="AccreditationsContainer">
        <div className="containerTitle">
          <h4>Acreditamientos</h4>
        </div>
      </div>
    )

  const handlesearchPayments = async (filter: typesearch) => {
    try {
      if (!idSearch) return
      startLoading()
      const data = await getsearchPayments(idSearch, filter)
      setGeneralData(lastData => (lastData ? { ...lastData, Reports: data } : undefined))
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setSelected(undefined)
    if (idSearch) {
      getGeneralData(idSearch)
    }
  }

  const barChartOptions = {
    title: {
      text: `Mes en curso ${moment(Date.now()).format('MM YYYY')}`,
      subtext: `CUOTA/RESERVA/MORA`,
      left: 'center',
      textStyle: {
        color: '#fff'
      }
    },
    tooltip: {
      trigger: 'axis'
    },
    xAxis: {
      type: 'category',
      data: ['TOTAL', 'CUOTA', 'RESERVA', 'MORA']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: 'TOTAL',
        type: 'bar',
        data: [
          GeneralData.grafTotal.payment + GeneralData.grafTotal.noPayment,
          GeneralData.grafQuotas.payment + GeneralData.grafQuotas.noPayment,
          GeneralData.grafReservations.payment + GeneralData.grafReservations.noPayment,
          GeneralData.grafLatePayments.payment + GeneralData.grafLatePayments.noPayment
        ],
        itemStyle: {
          color: '#f600c0' // Color personalizado para 'TOTAL'
        }
      },
      {
        name: 'ACREDITADOS',
        type: 'bar',
        data: [
          GeneralData.grafTotal.payment,
          GeneralData.grafQuotas.payment,
          GeneralData.grafReservations.payment,
          GeneralData.grafLatePayments.payment
        ],
        itemStyle: {
          color: '#cc00aa'
        }
      },
      {
        name: 'RESIDENTES AL DIA',
        type: 'bar',
        data: [
          GeneralData.grafTotal.residentsPayment,
          GeneralData.grafQuotas.residentsPayment,
          GeneralData.grafReservations.residentsPayment,
          GeneralData.grafLatePayments.residentsPayment
        ],
        itemStyle: {
          color: '#990093'
        }
      },
      {
        name: 'RESIDENTES POR PAGAR',
        type: 'bar',
        data: [
          GeneralData.grafTotal.residentsNoPayment,
          GeneralData.grafQuotas.residentsNoPayment,
          GeneralData.grafReservations.residentsNoPayment,
          GeneralData.grafLatePayments.residentsNoPayment
        ],
        itemStyle: {
          color: '#4409a4' // Color personalizado para 'TOTAL'
        }
      }
    ]
  }

  const handleChange = <K extends keyof typesearch>(params: {
    name: K
    value: typesearch[K]
  }) => {
    setSearch(prevSearch =>
      prevSearch
        ? {
            ...prevSearch,
            [params.name]: params.value
          }
        : { [params.name]: params.value }
    )
  }

  const StatusOptions = [
    { value: 'Pending', label: 'POR PAGAR' },
    { value: 'Process', label: 'EN VERIFICACIÓN' },
    { value: 'Payment', label: 'PAGADA' },
    { value: 'Reject', label: 'RECHAZADA' }
  ]

  return (
    <div className="AccreditationsContainer">
      <div className="containerTitle">
        <h4>Acreditamientos</h4>
      </div>
      {GeneralData && (
        <div>
          {selected && (
            <>
              {selected.action === 'viewPayment' && (
                <Modal
                  isOpen={isModalOpen}
                  styleContainer={{
                    width: '100%',
                    padding: '0px'
                  }}
                  onClose={closeModal}
                >
                  <PaymentsDetails PaymentId={selected.id} onClose={closeModal} />
                </Modal>
              )}
            </>
          )}

          <div className="accreditations-graf">
            <ReactECharts
              option={barChartOptions}
              style={{ height: '400px', width: '100%' }}
            />
          </div>

          <div className="accreditations-search-section">
            <h3 className="accreditations-barStatus">ESTATUS</h3>
            <DateRangePicker
              label1={'Desde'}
              label2={'Hasta'}
              onChange={value => handleChange({ name: 'date', value })}
            />

            <MultiSelectANTD
              placeholder="Estado"
              style={{ width: '170px' }}
              options={StatusOptions}
              value={search?.status}
              onChange={value => handleChange({ name: 'status', value })}
            />
            <MultiSelectANTD
              style={{ width: '170px' }}
              unique
              placeholder="Orden"
              options={[
                { value: 'asc', label: 'Recientes' },
                { value: 'desc', label: 'Antiguos' }
              ]}
              value={search?.order}
              onChange={value => handleChange({ name: 'order', value })}
            />

            <ExportToExcel filteredReports={GeneralData.Reports} style={{ height: '40px' }} />
            <div
              className="dashboard-search-bar-container-dashboard"
              style={{ height: '40px', marginBottom: '0px' }}
            >
              <div className="dashboard-search-icon">
                <Search style={{ color: 'white' }} />
              </div>
              <input
                name="Descripción..."
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange({ name: 'text', value: e.target.value })
                }
                type="text"
                className="dashboard-search-input"
                placeholder="Descripción..."
              />
            </div>
          </div>

          <div className="accreditations-container">
            <div className="order header">
              <span>DESTINO</span>
              <span>RESIDENTE</span>
              <span>MONTO</span>
              <span>CONCEPTO</span>
              <span>FECHA</span>
              <span>IMAGEN</span>
              <span>CUENTA</span>
              <span>ESTATUS</span>
              <span>ACCION</span>
            </div>

            {GeneralData.Reports.map((report, index) => (
              <div key={index} className="order Payments">
                <span>{report.destinationCode}</span>
                <span>{report.residentname}</span>
                <span >{report.amount.toFixed(2)}</span>
                <span>{report.concept}</span>
                <span>{report.createText}</span>

                <PhotoTooltip
                  photos={[report.photo]}
                  altText={report.concept}
                  altPhoto="images/logo_inicio.png"
                  selectPlacement="left-start"
                  className="custom-tooltip"
                />

                <span>{report.statusResident}</span>
                <span>{report.status}</span>

                <IconButton
                  onClick={() => {
                    setSelected({ id: report.id, action: 'viewPayment' })
                    setIsModalOpen(true)
                  }}
                >
                  <VisibilityIcon style={{ color: 'white' }} />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Accreditations
