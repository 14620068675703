import React, { FC, useEffect, useMemo, useState } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { Image } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { IconButton } from '@mui/material'
import Button from '../../../components/Form/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Update,
  CheckCircle,
  Cancel,
  DisabledByDefaultOutlined,
  IndeterminateCheckBoxOutlined,
  CheckBoxOutlined
} from '@mui/icons-material'
import { getPaymentDetail, processPayment } from '../../../services/Payments'
import './style.scss'
import { details, PaymentDetails } from '../../../types/Payments'
import { faCloud } from '@fortawesome/free-solid-svg-icons'

const PaymentsDetails: FC<{ PaymentId: string; onClose: () => void }> = ({
  PaymentId,
  onClose
}) => {
  const { startLoading, stopLoading } = useLoading()
  const [data, setData] = useState<PaymentDetails | undefined>(undefined)
  const [totalPayment, setTotalPayment] = useState<number>(0)
  const [considerStatus, setConsiderStatus] = useState(false) // Controla si se considera el status
  const [statustotal, setstatustotal] = useState<'Payment' | 'Process' | 'Reject'>('Process') // Controla si se considera el status
  const [buttonSelect, setButtonSelect] = useState<0 | 1 | 2 | 3>(0) // Controla si se considera el status

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (!considerStatus || !data) return
    console.log('estatus cambio')
    const filterDeletes = data.details.filter(detail => !detail.isDelete)
    const uniqueStatuses = new Set(filterDeletes.map(detail => detail.status))

    const value = (() => {
      if (uniqueStatuses.size > 1) {
        setstatustotal('Process')
        return 2
      }

      const [status] = uniqueStatuses

      switch (status) {
        case 'Payment':
          setstatustotal('Payment')
          return 1
        case 'Reject':
          setstatustotal('Reject')
          return 3
        case 'Process':
          setstatustotal('Process')
          return 0
        default:
          setstatustotal('Process')
          return 0
      }
    })()
    console.log(buttonSelect)

    setButtonSelect(value)
  }, [data])

  useMemo(() => {
    setTotalPayment(
      data
        ? data.details.reduce((total, detail) => {
            // Si no se debe considerar el status, suma todos los detalles
            if (!considerStatus) {
              return total + (detail.isDelete ? 0 : detail.price)
            }
            // Si se considera el status, solo suma aquellos con status 'Payment'
            return detail.status === 'Payment'
              ? total + (detail.isDelete ? 0 : detail.price)
              : total
          }, 0)
        : 0
    )
  }, [data, considerStatus])

  const getData = async () => {
    try {
      startLoading()
      const statement = await getPaymentDetail(PaymentId)
      setData(statement)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const precess = async (
    idPayment: string,
    details: details[],
    type: 'approve' | 'partially' | 'decline'
  ) => {
    try {
      startLoading()
      await processPayment(idPayment, type, details)
      onClose()
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const statusSvg: {
    [key: string]: { generalStatus: React.JSX.Element; detailsStatus: React.JSX.Element }
  } = {
    Payment: {
      generalStatus: <CheckCircle style={{ color: 'green' }} />,
      detailsStatus: <CheckBoxOutlined style={{ color: 'green' }} />
    },

    Process: {
      generalStatus: <Update style={{ color: 'yellow' }} />,
      detailsStatus: <IndeterminateCheckBoxOutlined style={{ color: 'antiquewhite' }} />
    },

    Reject: {
      generalStatus: <Cancel style={{ color: 'red' }} />,
      detailsStatus: <DisabledByDefaultOutlined style={{ color: 'red' }} />
    }
  }

  const handleStatus = (index: number) => {
    // Copia el estado actual de los detalles
    const updatedDetails = [...(data?.details || [])]

    // Obtén el estado actual del detalle
    const currentStatus = updatedDetails[index].status

    // Define el orden en que los estados cambiarán
    const statusOrder = ['Payment', 'Reject', 'Process'] as const

    // Encuentra el índice del estado actual en el arreglo statusOrder
    const currentStatusIndex = statusOrder.indexOf(currentStatus)

    // Calcula el siguiente estado (cíclico)
    const nextStatusIndex = (currentStatusIndex + 1) % statusOrder.length
    const nextStatus = statusOrder[nextStatusIndex]

    // Actualiza el estado del detalle
    updatedDetails[index].status = updatedDetails[index].isDelete
      ? 'Process'
      : (nextStatus as 'Payment' | 'Reject' | 'Process')

    // Actualiza el estado global con el nuevo arreglo de detalles
    setData(prevData => (prevData ? { ...prevData, details: updatedDetails } : undefined))

    setConsiderStatus(true)
  }

  const handleStatusGeneral = () => {
    // Copia el estado actual de los detalles
    const updatedDetails = [...(data?.details || [])]

    // Encuentra el índice del estado actual en el arreglo (asegúrate de definir 'statustotal')
    const statusOrder = ['Payment', 'Reject', 'Process'] as const
    const currentStatusIndex = statusOrder.indexOf(statustotal)

    // Calcula el siguiente estado (cíclico)
    const nextStatusIndex = (currentStatusIndex + 1) % statusOrder.length
    const nextStatus = statusOrder[nextStatusIndex] // El tipo será "Payment" | "Reject" por 'as const'

    // Mapea los detalles para actualizar el estado
    const newDetails = updatedDetails.map(detail => {
      return {
        ...detail,
        status: detail.isDelete ? 'Process' : (nextStatus as 'Payment' | 'Reject' | 'Process')
      } // 'nextStatus' ahora es "Payment" | "Reject"
    })

    // Actualiza el estado global con el nuevo arreglo de detalles
    setData(prevData => (prevData ? { ...prevData, details: newDetails } : undefined))
    setstatustotal(nextStatus)
    setConsiderStatus(true)
  }

  return (
    <div className="paymentsDetail-container">
      {data && (
        <>
          <div className="containerphoto">
            <div className="photo">
              <Image
                preview={{
                  mask: (
                    <div>
                      <EyeOutlined />
                      <span>Ver</span>
                    </div>
                  )
                }}
                wrapperStyle={{ width: '100%', height: '100%' }}
                style={{ width: '100%', height: '100%', objectFit: 'contain', margin: '0px' }}
                src={data.photo}
                className="image"
              />
            </div>
          </div>

          <div className="inf">
            <div className="database-container">
              <div className="data">
                <p>ACREDITÓ {data.datePayment || '--'}</p>
                <p>CONFIRMÓ {data.dateConfirm || '--'}</p>

                <p>{data.residentname}</p>

                <p>{data.clienName}</p>

                <p>ESTADO DE CUENTA - {data.datePayment}</p>
              </div>
              <div className="status">{statusSvg[data.status]?.generalStatus}</div>
            </div>

            <div className="statement">
              {data.details.map((details, index) => (
                <div key={index} className="detail">
                  <span>{details.type}</span>
                  <span>{details.concept}</span>
                  <span>{details.dateCreate}</span>
                  <span
                    style={
                      details.isDelete
                        ? {
                            textDecoration: 'line-through',
                            textDecorationColor: '#ff0000',
                            textDecorationThickness: '4px'
                          }
                        : {}
                    }
                  >
                    Q {details.price.toFixed(2)}
                  </span>
                  <IconButton
                    onClick={() => {
                      handleStatus(index)
                    }}
                    disabled={data.status !== 'Process' || details.isDelete}
                  >
                    {statusSvg[details.status].detailsStatus}
                  </IconButton>
                </div>
              ))}
            </div>
            <div className="totalStatement">
              <div>SALDO AL CORTE</div>
              <div>
                <span>Q {totalPayment.toFixed(2)}</span>
                <IconButton
                  onClick={() => {
                    handleStatusGeneral()
                  }}
                  disabled={data.status !== 'Process'}
                >
                  {statusSvg[statustotal].detailsStatus}
                </IconButton>
              </div>
            </div>
            <div className="actions">
              {[
                {
                  text: 'Confirmar todo',
                  condition: buttonSelect === 1 || buttonSelect === 0,
                  action: () => precess(data.idPayment, data.details, 'approve')
                },
                {
                  text: 'Confirmar parcial',
                  condition: buttonSelect === 2 || buttonSelect === 0,
                  action: () => precess(data.idPayment, data.details, 'partially')
                },
                {
                  text: 'Rechazo total',
                  condition: buttonSelect === 3 || buttonSelect === 0,
                  action: () => precess(data.idPayment, data.details, 'decline')
                }
              ].map(({ text, condition, action }, index) => {
                const isDisabled = data.status !== 'Process' || !condition
                const styleType = isDisabled ? 'disable' : 'normal'

                return (
                  <Button
                    key={index}
                    disabled={isDisabled}
                    text={text}
                    styleType={styleType}
                    onClick={action}
                    icon={<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faCloud} />}
                  />
                )
              })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default PaymentsDetails
