import React, { useEffect, useState, useMemo } from 'react'
/* import { useLoading } from '../../Provider/LoadingProvider' */
import './style.scss'
import { useAuth } from '../../Provider/AuthProvider'
import { useLoading } from '../../Provider/LoadingProvider'

import Button from '../../components/Form/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud } from '@fortawesome/free-solid-svg-icons'
import { savePaymentDate } from '../../services/manageress'
import { PaymentDateType } from '../../types/client'
import Switch from '../../components/Form/Switch'

function arePaymentDatesDifferent(obj1: PaymentDateType, obj2: PaymentDateType): boolean {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // Si tienen diferente cantidad de claves, son diferentes
  if (keys1.length !== keys2.length) {
    return true
  }

  // Comparar los valores de cada clave
  for (const key of keys1) {
    const monthKey = parseInt(key, 10)
    const val1 = obj1[monthKey]
    const val2 = obj2[monthKey]

    // Si alguno de los objetos no tiene esta clave, son diferentes
    if (!val2) {
      return true
    }

    // Comparar las propiedades internas
    if (val1.courtDate !== val2.courtDate || val1.Deadline !== val2.Deadline) {
      return true
    }
  }

  return false // Si todo coincide, son iguales
}

const Component: React.FC = () => {
  /*   const { startLoading, stopLoading } = useLoading()*/

  const { user, initial } = useAuth()
  const { startLoading, stopLoading } = useLoading()

  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ]
  const days = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']

  const [selectedDates, setSelectedDates] = useState<PaymentDateType>({})
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [valueSelect, setValueSelect] = useState<boolean>(false)
  const year = new Date().getFullYear()

  const paymentDate: PaymentDateType | undefined = useMemo(() => {
    if (!user?.client?.paymentDate) return {}

    try {
      return user.client.paymentDate
    } catch (error) {
      console.error('Invalid date:', user.client.paymentDate)
      return {}
    }
  }, [user])

  const getFirstWeekday = (month: number) => {
    let day = 1
    while (true) {
      const date = new Date(year, month, day)
      const dayOfWeek = date.getDay()
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        return day
      }
      day++
    }
  }

  useEffect(() => {
    // Crear una copia del objeto paymentDate
    const initialSelectedDates: PaymentDateType = { ...paymentDate }

    for (let i = 0; i < months.length; i++) {
      const monthIndex = i + 1

      // Asegurarse de que el objeto para el mes existe
      if (!initialSelectedDates[monthIndex]) {
        initialSelectedDates[monthIndex] = { courtDate: 0, Deadline: 0 }
      }

      // Asignar valores si están vacíos
      if (!initialSelectedDates[monthIndex].courtDate) {
        initialSelectedDates[monthIndex].courtDate = getFirstWeekday(i)
      }

      if (!initialSelectedDates[monthIndex].Deadline) {
        initialSelectedDates[monthIndex].Deadline = getFirstWeekday(i)
      }
    }

    // Establecer las fechas seleccionadas en el estado
    setSelectedDates(initialSelectedDates)
  }, [user, months.length, paymentDate])

  useEffect(() => {
    const hasChanges = arePaymentDatesDifferent(paymentDate, selectedDates)

    setHasChanges(hasChanges)
  }, [selectedDates, paymentDate])

  const handleDateClick = (month: number, day: number) => {
    setSelectedDates(prevSelectedDates => ({
      ...prevSelectedDates,
      [month]: {
        ...prevSelectedDates[month],
        ...(valueSelect ? { Deadline: day } : { courtDate: day })
      }
    }))
  }

  const handleSaveClick = async () => {
    if (hasChanges) {
      try {
        startLoading()
        await savePaymentDate(selectedDates)
        initial()
      } catch (error) {
        console.error(error)
      } finally {
        stopLoading()
      }
    }
  }

  return (
    <div>
      <div className="title">
        <h4>Fechas de pagos</h4>
        <p></p>
      </div>

      <div className="containerButtons">
        <Button
          text="Guardar"
          styleType={hasChanges ? `normal` : `disable`}
          icon={<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faCloud} />}
          onClick={handleSaveClick}
        />
        <div className="SelectValue">
          <p>Fecha Corte</p>
          <Switch
            activeColor={'#ff0000'}
            ColorDiscative={'#ff65c3'}
            value={valueSelect}
            onChange={() => {
              setValueSelect(!valueSelect)
            }}
          />
          <p>Fecha Limite</p>
        </div>
      </div>

      <div className="gridContainer">
        {months.map((month, index) => (
          <div key={index} className="calendarMonth">
            <div
              className={index === new Date().getMonth() ? 'monthHeaderSelect' : `monthHeader`}
            >
              {month}
            </div>
            <div className="DaysCalendar">
              {days.map(dia => (
                <div key={dia} className="DayCalendar">
                  {dia}
                </div>
              ))}
            </div>
            <div className="daysGrid">
              {Array.from({ length: 42 }, (_, i) => {
                const day = i - (new Date(year, index, 1).getDay() - 1)
                const isCurrentMonth = day > 0 && day <= new Date(year, index + 1, 0).getDate()
                const isSelectedDeadline = selectedDates[index + 1]?.Deadline === day
                const isSelectedcourtDate = selectedDates[index + 1]?.courtDate === day
                const colorSelect =
                  isSelectedDeadline && isSelectedcourtDate
                    ? 'mix'
                    : isSelectedDeadline
                    ? 'limit'
                    : isSelectedcourtDate
                    ? 'Court'
                    : undefined

                return (
                  <button
                    key={i}
                    className={`
                      dayButton 
                      ${!isCurrentMonth ? 'inactive' : ''} 
                      ${colorSelect ? `${colorSelect} selected` : ''}
                    `}
                    onClick={() => handleDateClick(index + 1, day)}
                    disabled={!isCurrentMonth}
                  >
                    {isCurrentMonth ? day : ''}
                  </button>
                )
              })}
            </div>
          </div>
        ))}
      </div>
      <div className="secondContainerButtons">
        <Button
          text="Guardar"
          styleType={hasChanges ? `normal` : `disable`}
          icon={<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faCloud} />}
          onClick={handleSaveClick}
        />
      </div>
    </div>
  )
}

export default Component
