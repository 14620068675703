import React from 'react'
import * as XLSX from 'xlsx'
import { searchPayment } from '../../types/Payments'
import { Button, ConfigProvider } from 'antd'
import { ArrowDownOutlined } from '@ant-design/icons'

interface ExportToExcelProps {
  filteredReports: searchPayment[]
  style?: React.CSSProperties
}

const ExportToExcel: React.FC<ExportToExcelProps> = ({ filteredReports, style }) => {
  const handleExport = () => {
    // Map filteredReports to an array of objects
    const data = filteredReports.map(report => ({
      Destino: report.destinationCode,
      'Nombre Apellido': report.residentname,
      Estado: report.status,
      Monto: report.amount,
      Concepto: report.concept,
      Fecha: report.createdAt,
      Foto: report.photo
    }))

    // Create a new workbook and a worksheet
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(data)

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Reportes')

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, 'reportes.xlsx')
  }

  return (
    <div style={style}>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              colorBgContainer: 'rgba(56, 78, 183, 0.3)',
              colorBorder: '#484747',
              colorPrimary: 'white',
              hoverBorderColor: 'white',
              colorText: 'white',
              colorTextBase: 'white',
              optionSelectedColor: 'white',
              selectorBg: 'rgba(56, 78, 183, 0.3)',
              optionSelectedBg: 'rgba(56, 78, 183, 0.3)',
              multipleItemBg: 'rgba(56, 78, 183, 0.3)',
              colorIcon: 'white',
              colorIconHover: 'white',
              colorBorderBg: 'rgba(56, 78, 183, 0.3)',
              activeBorderColor: 'white'
            }
          },
          token: {
            colorBgBase: 'rgba(56, 78, 183, 0.3)',
            colorBgContainer: 'rgba(56, 78, 183, 0.3)',
            colorText: 'white',
            colorTextPlaceholder: 'white',
            colorIcon: 'white',
            colorIconHover: 'white',
            colorPrimaryHover: 'white',
            colorPrimaryTextHover: 'white',
            colorLinkActive: 'white',
            colorLink: 'white',
            colorLinkHover: 'white',
            colorPrimaryActive: 'white',
            colorPrimaryTextActive: 'white'
          }
        }}
      >
        <Button
          onClick={handleExport}
          icon={<ArrowDownOutlined />}
          style={{ height: '100%', borderColor: '#80808029' }}
        >
          Excel
        </Button>
      </ConfigProvider>
    </div>
  )
}

export default ExportToExcel
