// CustomMessage.tsx
import React from 'react'
import './style.scss'

export type MessageType = 'success' | 'info' | 'warning' | 'error'

interface CustomMessageProps {
  type: MessageType
  message: string
  visible: boolean
  onClose: () => void
}

const CustomMessage: React.FC<CustomMessageProps> = ({ type, message, visible, onClose }) => {
  // Dividimos el mensaje en líneas por '\n'
  const messageLines = message.split('\n')

  return (
    <>
      {visible && (
        <div className="popup-overlay">
          <div className="popup">
            <div className={`${type}-icon`}></div>

            {/* Renderizamos cada línea como un párrafo */}
            <div className="message">
              {messageLines.map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </div>

            <button
              className="register-button"
              style={{ display: 'inline', width: '250px', height: '46px' }}
              onClick={onClose}
            >
              Entendido
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default CustomMessage
