import { Staff } from '../types/staff'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listStaffClient = async ({
  id,
  params
}: {
  id: string
  params: PaginationQuery
}): Promise<Pagination<Staff>> => {
  try {
    const response = await axiosInstance.get(`/api/staff/${id}`, {
      params
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en listStaffClient :', error)
    throw error
  }
}

export const createStaff = async (params: FormData): Promise<Staff> => {
  try {
    const response = await axiosInstance.post(`/api/staff`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en createStaff :', error)
    throw error
  }
}
export const updateStaff = async (id: string, params: FormData): Promise<Staff> => {
  try {
    const response = await axiosInstance.post(`/api/staff/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en  updateStaff :', error)
    throw error
  }
}
export const deleteStaff = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/staff/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en deleteStaff :', error)
    throw error
  }
}

export const NotificationStaff = async (id: string, notification: boolean): Promise<Staff> => {
  try {
    const response = await axiosInstance.post(`/api/staff/notifications/${id}`, {
      enableNotification: notification
    })

    return response.data
  } catch (error) {
    console.error('Error en NotificationStaff:', error)
    throw error
  }
}
