import moment from 'moment'
import { GeneralData } from '../../types/dashboard'
const capitalice = (text: string) =>
  text.toLowerCase().replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()))

export const barChartOptions = (GeneralData: GeneralData) => ({
  title: {
    text: 'Reportes / Emergencias',
    subtext: `Mes en curso ${capitalice(moment().locale('es').format('MMMM'))}`,
    left: '50%', // Asegura que siempre esté centrado
    textAlign: 'center', // Alinea el texto centralmente con respecto a su contenedor
    width: '100%', // Hace que el ancho del título sea relativo al contenedor
    textStyle: {
      color: '#fff',
      fontSize: 18 // Puedes ajustar el tamaño según tus necesidades
    },
    subtextStyle: {
      color: '#ccc',
      fontSize: 14 // Ajuste de tamaño del subtítulo
    }
  },
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    textStyle: { color: '#fff' },
    data: ['Ingresados', 'Cerrados', 'Abiertos', 'En espera', 'Evaluación', 'Irrelevante'],
    left: 'center',
    bottom: '1%'
  },
  xAxis: {
    type: 'category',
    data: ['Total', 'Reportes', 'Emergencias', 'Express', 'CheckPoint']
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      name: 'Ingresados',
      type: 'bar',
      data: [
        GeneralData.T_In,
        GeneralData.RS_In,
        GeneralData.E_In,
        GeneralData.ExPress_In,
        GeneralData.CheckPoint_In
      ]
    },
    {
      name: 'Cerrados',
      type: 'bar',
      data: [
        GeneralData.RS_Close +
          GeneralData.E_Close +
          GeneralData.ExPress_Close +
          GeneralData.CheckPoint_Close,

        GeneralData.RS_Close,
        GeneralData.E_Close,
        GeneralData.ExPress_Close,
        GeneralData.CheckPoint_Close
      ]
    },
    {
      name: 'Abiertos',
      type: 'bar',
      data: [
        GeneralData.RS_Open +
          GeneralData.E_Open +
          GeneralData.ExPress_Open +
          GeneralData.CheckPoint_Open,

        GeneralData.RS_Open,
        GeneralData.E_Open,
        GeneralData.ExPress_Open,
        GeneralData.CheckPoint_Open
      ]
    },
    {
      name: 'En espera',
      type: 'bar',
      data: [
        GeneralData.RS_Hold +
          GeneralData.E_Hold +
          GeneralData.ExPress_Hold +
          GeneralData.CheckPoint_Hold,

        GeneralData.RS_Hold,
        GeneralData.E_Hold,
        GeneralData.ExPress_Hold,
        GeneralData.CheckPoint_Hold
      ]
    },
    {
      name: 'Evaluación',
      type: 'bar',
      data: [
        GeneralData.RS_Evaluate +
          GeneralData.E_Evaluate +
          GeneralData.ExPress_Evaluate +
          GeneralData.CheckPoint_Evaluate,

        GeneralData.RS_Evaluate,
        GeneralData.E_Evaluate,
        GeneralData.ExPress_Evaluate,
        GeneralData.CheckPoint_Evaluate
      ]
    },
    {
      name: 'Irrelevante',
      type: 'bar',
      data: [
        GeneralData.RS_Spam +
          GeneralData.E_Spam +
          GeneralData.ExPress_Spam +
          GeneralData.CheckPoint_Spam,

        GeneralData.RS_Spam,
        GeneralData.E_Spam,
        GeneralData.ExPress_Spam,
        GeneralData.CheckPoint_Spam
      ]
    }
  ]
})

export const pieChartOptions = (GeneralData: GeneralData) => ({
  title: {
    text: 'Estado Global',
    subtext: `Mes en curso ${capitalice(moment().locale('es').format('MMMM'))}`,
    left: 'center',
    textStyle: {
      color: '#fff'
    }
  },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    left: 'center',
    bottom: '1%',
    textStyle: {
      color: `#fff`
    },
    data: ['Cerrados', 'Abiertos', 'Espera', 'Irrelevante', 'Evaluación'] // Deben coincidir con los nombres de los datos en la serie
  },
  series: [
    {
      name: 'Estado',
      type: 'pie',
      radius: '50%',
      label: {
        color: '#fff' // Mover el textStyle a nivel superior
      },
      data: [
        { value: GeneralData.T_Close, name: 'Cerrados' },
        { value: GeneralData.T_Open, name: 'Abiertos' },
        { value: GeneralData.T_Hold, name: 'Espera' },
        { value: GeneralData.T_Spam, name: 'Irrelevante' },
        { value: GeneralData.T_Evaluate, name: 'Evaluación' }
      ]
    }
  ]
})
