import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DislikeOutlined
} from '@ant-design/icons'
import Icon from '@ant-design/icons'
import { ReactComponent as waitInLobby } from '../../assets/svg/waitInLobby.svg'
import { ReactComponent as leaveInLobby } from '../../assets/svg/leaveInLobby.svg'
import { ReactComponent as noLeaveInLobby } from '../../assets/svg/noLeaveInLobby.svg'
import QRCodeIcon from '@mui/icons-material/QrCode'

import { Fade, IconButton } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { LightTooltip } from '../../components/tooltips/text'
import { entries } from '../../types/entries'
import Table, { enumTypeColumn, TableColumn } from '../../components/Table'
import { columnsCheckIn } from '../../components/Tables/CheckIn/columns'
import { useLoading } from '../../Provider/LoadingProvider'
import { useAuth } from '../../Provider/AuthProvider'
import { listEntriesByClient } from '../../services/entries'
import { convertEntriesAttributes } from '../../utils/utils'
import { Pagination } from '../../types/types'
import { io } from 'socket.io-client'

const TableCheckIn: FC<{ handleOpenModal: () => void }> = ({ handleOpenModal }) => {
  const { user, token } = useAuth()
  const { startLoading, stopLoading } = useLoading()

  const [data, setData] = useState<Pagination<entries>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })

  useEffect(() => {
    getDataCheckIn('', 1)
  }, [])

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL_BACKEND as string)
    const socketWhats = io(
      process.env.REACT_APP_API_URL_WHATS || ('https://whats.ipass.com.gt' as string)
    )

    socket.on(`newEntry-${user?.clientId}`, (message: any) => {
      setData(prevData => ({
        ...prevData,
        data: [convertEntriesAttributes(message.newEntry), ...prevData.data]
      }))
    })
    socket.on(`updateEntry-${user?.clientId}`, (message: any) => {
      if (data.currentPage !== 1) return
      getDataCheckIn('', 1, true)
    })
    socketWhats.on(`updateEntry-${user?.clientId}`, (message: any) => {
      if (data.currentPage !== 1) return
      getDataCheckIn('', 1, true)
    })

    return () => {
      socket.disconnect()
      socketWhats.disconnect()
    }
  }, [])
  const handlePageChange = (newPage: number) => {
    getDataCheckIn('', newPage)
  }
  const getDataCheckIn = async (searchValue: string, page: number, noLoading?: boolean) => {
    try {
      !noLoading && startLoading()
      const entries = await listEntriesByClient({
        id: user?.clientId as string,
        params: { page, search: searchValue },
        token
      })
      setData({
        ...entries,
        data: entries.data.map(convertEntriesAttributes)
      })
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const statusSvg: {
    [key: string]: React.JSX.Element
  } = {
    WAITING: <ClockCircleOutlined style={{ color: 'yellow' }} />,
    APPROVED: <CheckCircleOutlined style={{ color: 'green' }} />,
    REJECT: <DislikeOutlined style={{ color: 'red' }} />,
    RECESSION: <Icon component={waitInLobby} />,
    LEAVE: <Icon component={leaveInLobby} />,
    NO_LEAVE: <Icon component={noLeaveInLobby} />,
    UNANSWERED: <CloseCircleOutlined style={{ color: 'red' }} />
  }

  const showAProved: TableColumn[] = [
    ...columnsCheckIn,
    {
      title: 'Estados',
      dataIndex: 'approved',
      key: 'approved',
      type: enumTypeColumn.custom,
      CustomRender: ({ item }: { id: string; item?: entries }) => {
        return (
          <div className="personalStatus">
            <LightTooltip
              title={
                item && item.cameraIn && item.authorizedIn && item.approved !== 'WAITING' ? (
                  <>
                    {`INGRESO:${item?.cameraIn}\n${item?.authorizedIn}`}
                    {item?.cameraOut
                      ? `\nEGRESO:${item?.cameraOut}\n${item?.authorizedOut}`
                      : ''}
                  </>
                ) : (
                  'ESPERANDO'
                )
              }
              followCursor
              TransitionComponent={Fade}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '30px',
                  gap: '0.5rem'
                }}
              >
                {item?.approved ? statusSvg[item?.approved] : statusSvg.WAITING}
              </div>
            </LightTooltip>

            <IconButton onClick={() => handleIconClick(item)}>
              <QRCodeIcon
                style={{
                  color: item?.approved ? (item?.timeOut ? 'red' : 'green') : 'yellow'
                }}
              />
            </IconButton>
          </div>
        )
      }
    }
  ]
  const handleIconClick = (item?: entries) => {
    console.info(item)
  }

  return (
    <Table
      pagination={data}
      handleOpenModal={handleOpenModal}
      onPageChange={handlePageChange}
      columns={showAProved}
      itemsPerPage={6}
      canAdd={false}
    />
  )
}

export default TableCheckIn
