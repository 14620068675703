// pages/CheckPoints/Locations/.tsx
import React, { useEffect, useState } from 'react'

import { useLoading } from '../../../Provider/LoadingProvider'
import { columnsCheckPoints } from '../../../components/Tables/CheckPoint/Columns'
import FormCheckPointsLocation from '../../../components/Tables/CheckPoint/Form'
import { Pagination } from '../../../types/types'
import './style.scss'

import { IconButton, Fade } from '@mui/material'
import { useAuth } from '../../../Provider/AuthProvider'
import Modal from '../../../components/GeneralComponents/Modal'
import Table from '../../../components/Table'
import DeleteButton from '../../../components/Table/components/DeleteButton'
import ViewQr from '../../../components/ViewQr'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { LightTooltip } from '../../../components/tooltips/text'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt'
import {
  deleteCheckPointsLocation,
  listCheckPoints,
  regenerateCode,
  getAllCheckPoints
} from '../../../services/checkPoint'
import { CheckPointsLocation } from '../../../types/CheckPoints_Location'
import { downloadAllImages } from '../../../components/ViewQr/ZipDownloaderQR'

const CheckPointsPage = () => {
  const { user } = useAuth()
  const [data, setData] = useState<Pagination<CheckPointsLocation>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const limit = 6
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredBySearch, setFilteredBySearch] = useState(false)
  const [selected, setSelected] = useState<CheckPointsLocation | undefined>(undefined)

  const { startLoading, stopLoading } = useLoading()

  useEffect(() => {
    getData(search.length > 3 ? search : '', data.currentPage)
  }, [])

  useEffect(() => {
    if (search.length >= 3) {
      setFilteredBySearch(true)
      getData(search, 1)
    } else if (filteredBySearch) {
      setFilteredBySearch(false)
      getData('', 1)
    }
  }, [search])

  useEffect(() => {
    getData(search, data.currentPage)
  }, [data.currentPage])

  useEffect(() => {
    if (selected) {
      setIsOpenModal(true)
    }
  }, [selected])

  const onCloseModal = () => {
    setIsOpenModal(false)
    setSelected(undefined)
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const getData = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const checkPoints = await listCheckPoints({
        params: { page, search: searchValue, limit },
        id: user?.clientId as string
      })
      setData(checkPoints)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleSubmit = async () => {
    setSelected(undefined)
    setIsOpenModal(false)
    await getData(search.length > 3 ? search : '', data.currentPage)
  }

  const handlePageChange = (newPage: number) => {
    getData(search.length >= 3 ? search : '', newPage)
  }

  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteCheckPointsLocation(id)
      await getData(search.length > 3 ? search : '', data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const downloadAllImagesForZip = async (type: 'limpieza' | 'mantenimiento' | 'Seguridad') => {
    if (!user?.clientId) return
    try {
      startLoading()
      const items: CheckPointsLocation[] = await getAllCheckPoints(user.clientId, type)
      await downloadAllImages(items, `${user?.client?.name}`, type)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const handleView = (value: CheckPointsLocation) => {
    setSelected(value)
  }

  const Actions = ({ id, item }: { id: string; item: CheckPointsLocation }) => {
    return (
      <div className="actionsContainer">
        <IconButton onClick={() => handleView(item as CheckPointsLocation)}>
          <VisibilityIcon style={{ color: 'white' }} />
        </IconButton>
        <ViewQr
          regenerateCode={id => regenerateCode(id)}
          onSubmit={() => getData(search.length > 3 ? search : '', data.currentPage)}
          item={item as CheckPointsLocation}
          isCheckPoints={true}
          text={{
            clientName: `${user?.client?.name}`,
            names: `${item.name}`,
            name: item.name,
            lastName: '',
            type: `CHECKPOINT ${item.type}`,
            destine: item.address,
            typeCheckPoint: item.type
          }}
        />

        <DeleteButton OnDelete={() => handleDelete(id)} />
      </div>
    )
  }

  return (
    <>
      <div className="checkPointsContainer">
        <div className="titleCheckPoints">
          <h4>Locaciones</h4>
        </div>
        <div className="tableContainer">
          <Table
            onPageChange={handlePageChange}
            onChangeSearch={setSearch}
            pagination={data}
            itemsPerPage={limit} //6
            handleOpenModal={handleOpenModal}
            columns={columnsCheckPoints}
            Actions={Actions as any}
            AdditionalButtons={() => (
              <div className="container-qr-Zip">
                <LightTooltip title="Descarga zip de Limpieza" TransitionComponent={Fade}>
                  <IconButton
                    onClick={() => {
                      downloadAllImagesForZip('limpieza')
                    }}
                  >
                    <SystemUpdateAltIcon style={{ color: 'white' }} sx={{ fontSize: 50 }} />
                  </IconButton>
                </LightTooltip>

                <LightTooltip title="Descarga zip de Mantenimiento" TransitionComponent={Fade}>
                  <IconButton
                    onClick={() => {
                      downloadAllImagesForZip('mantenimiento')
                    }}
                  >
                    <SystemUpdateAltIcon style={{ color: 'green' }} sx={{ fontSize: 50 }} />
                  </IconButton>
                </LightTooltip>

                <LightTooltip title="Descarga zip de Seguridad" TransitionComponent={Fade}>
                  <IconButton
                    onClick={() => {
                      downloadAllImagesForZip('Seguridad')
                    }}
                  >
                    <SystemUpdateAltIcon style={{ color: '#002d80' }} sx={{ fontSize: 50 }} />
                  </IconButton>
                </LightTooltip>
              </div>
            )}
          />
        </div>
      </div>
      <Modal isOpen={isOpenModal} onClose={onCloseModal}>
        <FormCheckPointsLocation onSubmit={handleSubmit} initialValue={selected} />
      </Modal>
    </>
  )
}

export default CheckPointsPage
