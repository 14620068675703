import React from 'react'
import newReactDOM from 'react-dom/client'
import ReactDOM from 'react-dom' // Actualiza la importación para React 18
import App from './App'
import { HashRouter } from 'react-router-dom'

const isInElectron = window && (window as any).ipcRenderer

if (isInElectron) {
  console.log('log desde buid ELECTRON 1')

  ReactDOM.render(
    <React.StrictMode>
      <HashRouter>
        <App isCheckIn={false} />
      </HashRouter>
    </React.StrictMode>,
    document.getElementById('root')
  )
} else {
  newReactDOM.createRoot(document.getElementById('root')!).render(
    <HashRouter>
      <App isCheckIn={false} />
    </HashRouter>
  )
}
