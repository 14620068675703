import React, { useEffect, useState } from 'react'
import './style.scss'

export interface ReportModalProps {
  onClose: () => void
}

export const MiniLoadingToken: React.FC<ReportModalProps> = ({ onClose }) => {
  const [stepTwo, setstepTwo] = useState<boolean>(false)

  useEffect(() => {
    const interval = setTimeout(() => {
      setstepTwo(true)
    }, 60000) //  1 minutos 60000

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="miniDeleteLoading">
      {!stepTwo && (
        <>
          <div className="loading">
            <div className="loading-circle"></div>
          </div>
          <p>{`Solicitud enviada, vamos a esperar que tu corporativo responda, no cierres esta pantalla.`}</p>
        </>
      )}

      {stepTwo && (
        <>
          <h3>UPSS!</h3>
          <p>{`Tu corporativo parece estar ocupado `}</p>
          <p>{`No te preocupes, cuando tengas el token vuelve acá y directamente introdúcelo, eso será todo.`}</p>
          <div className="buttonContainer">
            <div className={`button ${'borderAnimateIpass'} `} onClick={onClose}>
              <div className="inner">ENTENDIDO</div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
