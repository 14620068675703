import React, { useState } from 'react'
import { Switch as AntSwitch, ConfigProvider } from 'antd'
import './style.scss'
import PopUpConfirm from '../../Table/components/PopupConfirm'
interface SwitchProps {
  value?: boolean
  onChange: (checked: boolean) => void
  label?: string
  activeColor?: string
  ColorDiscative?: string
  PopUp?: PopUpType
  disabled?: boolean //desactiva boton
}

export interface PopUpType {
  title: string
  description: string
  acceptText: string
  rejectText: string
}

const Switch: React.FC<SwitchProps> = ({
  value,
  onChange,
  label,
  activeColor,
  ColorDiscative,
  PopUp,
  disabled
}) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)

  const handleAccept = () => {
    setIsPopUpOpen(false)
    onChange(!value)
  }

  const handleReject = () => {
    setIsPopUpOpen(false)
  }

  const handleOnChange = () => {
    if (PopUp) {
      setIsPopUpOpen(true)
    } else {
      onChange(!value)
    }
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          ...(ColorDiscative ? { colorTextQuaternary: ColorDiscative } : {}),
          ...(ColorDiscative ? { colorTextTertiary: `${ColorDiscative}81` } : {}), //al hacer hover
          ...(activeColor ? { colorPrimaryHover: `${activeColor}81` } : {}), //al hacer hover
          ...(activeColor ? { colorPrimary: activeColor } : {})
        }
      }}
    >
      <div className="switch-container">
        {label && <label className="label">{label}</label>}
        <AntSwitch checked={value} onChange={handleOnChange} disabled={disabled} />
      </div>

      {PopUp && (
        <PopUpConfirm
          isOpen={isPopUpOpen}
          onClose={() => setIsPopUpOpen(false)}
          title={PopUp.title}
          description={PopUp.description}
          acceptText={PopUp.acceptText}
          rejectText={PopUp.rejectText}
          handleAccept={handleAccept}
          handleReject={handleReject}
        />
      )}
    </ConfigProvider>
  )
}

export default Switch
