import { TableColumn, enumTypeColumn } from '../../Table'

export const columnsCheckIn: TableColumn[] = [
  {
    title: 'id',
    dataIndex: 'showId',
    key: 'showId',
    type: enumTypeColumn.string
  },
  {
    title: 'Foto',
    dataIndex: '3photo',
    dataArray: ['photo', 'DNI_A', 'DNI_B'],
    key: 'dataArray',
    type: enumTypeColumn.photo3
  },
  {
    title: 'DNI',
    dataIndex: 'DNI',
    key: 'DNI',
    type: enumTypeColumn.string
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'Apellido',
    dataIndex: 'lastName',
    key: 'lastName',
    type: enumTypeColumn.string
  },
  {
    title: 'Destino',
    dataIndex: 'destination',
    key: 'destination',
    type: enumTypeColumn.string
  },
  {
    title: 'Origen',
    dataIndex: 'origin',
    key: 'origin',
    type: enumTypeColumn.string
  },
  {
    title: 'Marca',
    dataIndex: 'brand',
    key: 'brand',
    type: enumTypeColumn.string
  },
  {
    title: 'Fecha IN',
    dataIndex: 'dateIn',
    key: 'dateIn',
    type: enumTypeColumn.date,
    options: 'day'
  },
  {
    title: 'Hora IN',
    dataIndex: 'timeIn',
    key: 'timeIn',
    type: enumTypeColumn.date,
    options: 'time'
  },
  {
    title: 'Fecha OUT',
    dataIndex: 'dateOut',
    key: 'dateOut',
    type: enumTypeColumn.date,
    options: 'day'
  },

  {
    title: 'Hora OUT',
    dataIndex: 'timeOut',
    key: 'timeOut',
    type: enumTypeColumn.date,
    options: 'time'
  }
]
