// src/services/residents.ts
import { Resident, ResidentCreate } from '../types/resident'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listResidents = async ({
  id,
  params
}: {
  id: string
  params: PaginationQuery
}): Promise<Pagination<Resident>> => {
  try {
    const response = await axiosInstance.get(`/api/residents/byClient/${id}`, {
      params
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de residentes:', error)
    throw error
  }
}

export const createResident = async (params: ResidentCreate): Promise<Resident> => {
  try {
    const response = await axiosInstance.post('/api/residents', JSON.stringify(params), {})

    return response.data
  } catch (error) {
    console.error('Error en la creación de Residente:', error)
    throw error
  }
}
export const createResidents = async (params: { residents: any[] }): Promise<Resident> => {
  try {
    const response = await axiosInstance.post(
      '/api/residents/massive',
      JSON.stringify(params),
      {}
    )

    return response.data
  } catch (error) {
    console.error('Error en la creación de Residente:', error)
    throw error
  }
}

export const updateResident = async (
  id: string,
  params: ResidentCreate
): Promise<Resident> => {
  try {
    const response = await axiosInstance.post(
      `/api/residents/${id}`,
      JSON.stringify(params),
      {}
    )

    return response.data
  } catch (error) {
    console.error('Error en la creación de Residente:', error)
    throw error
  }
}

export const enableResident = async (
  id: string,
  params: {
    enable: boolean
    visitor: boolean
    guest: boolean
    worker: boolean
    delegate: boolean
  }
): Promise<Resident> => {
  try {
    const response = await axiosInstance.post(
      `/api/residents/enable/${id}`,
      JSON.stringify(params),
      {}
    )

    return response.data
  } catch (error) {
    console.error('Error en la Activación de Residente:', error)
    throw error
  }
}

export const NotificationResident = async (id: string, value: boolean): Promise<Resident> => {
  try {
    const response = await axiosInstance.post(`/api/residents/notification/${id}`, {
      enableNotification: value
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error en actualizar notificaciones de resident', error)
    throw error
  }
}

export const deleteResident = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/residents/${id}`)

    return response.data
  } catch (error) {
    console.error('Error al eliminar Residente:', error)
    throw error
  }
}

export const regenerateCode = async (id: string): Promise<{ qr: string }> => {
  try {
    const response = await axiosInstance.post(`/api/residents/regenerate_qr/${id}`)

    return response.data
  } catch (error) {
    console.error('Error al eliminar Residente:', error)
    throw error
  }
}

export const getResident = async (id: string): Promise<Resident> => {
  try {
    const response = await axiosInstance.get(`/api/residents/${id}`)

    return response.data.resident
  } catch (error) {
    console.error('Error en la solicitud para obtener residente:', error)
    throw error
  }
}

export const listByDestinationCode = async (params: {
  search: string
  clientId: string
}): Promise<Resident[]> => {
  try {
    const response = await axiosInstance.get(`/api/residents/search_code`, {
      params
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de residentes:', error)
    throw error
  }
}

export const resetResidentQr = async (id: string): Promise<Resident> => {
  try {
    const response = await axiosInstance.post(`/api/residents/reset_qr/${id}`)

    return response.data
  } catch (error) {
    console.error('Error en la creación de Residente:', error)
    throw error
  }
}

//borrar MONJEZ esto es para pruebas de generacion de facturas
export const generarfacturas = async () => {
  try {
    await axiosInstance.patch(`/api/payments/auto_update`)
  } catch (error) {
    console.error('Error en la solicitud de la lista de residentes:', error)
    throw error
  }
}
