import React, { useEffect, useState } from 'react'
import './style.scss'
import io from 'socket.io-client'

import { useAuth } from '../../../Provider/AuthProvider'
import { useLoading } from '../../../Provider/LoadingProvider'
import { PaymentDetaild } from '../../../types/Payments'
import { InputOTP } from '../../Form/labelOTP'
import {
  GetTokentForDelete,
  verifyAndDelete
} from '../../../services/PaymentsConceptsResident'
import { LabelANTD } from '../../Form/label'
import Modal from '../../GeneralComponents/Modal'
import { MiniLoadingToken } from './MiniLoadingToken'
import { TokenErrorModal } from './TokenErrorModal'

export interface ReportModalProps {
  paymentDetaild: PaymentDetaild
  title: string
  onClose: () => void
}

export const PopUpDeleteConcept: React.FC<ReportModalProps> = ({
  paymentDetaild,
  title,
  onClose
}) => {
  const [error, setError] = useState(false)
  const [errorOTP, setErrorOTP] = useState(false)
  const { startLoading, stopLoading } = useLoading()
  const [valuesOTP, setValuesOTP] = useState<string[]>(Array(6).fill(''))
  const [isWaiting, setIsWaiting] = useState<boolean>(false)
  const [formState, setFormState] = useState<string>('')
  const [isopenModal, setisopenModal] = useState<boolean>(false)
  const [isTokenError, setIsTokenError] = useState<boolean>(false)

  const { user } = useAuth()

  useEffect(() => {
    const socketWhats = io(
      process.env.REACT_APP_API_URL_WHATS || ('https://whats.ipass.com.gt' as string)
    )

    socketWhats.on(`tokentDeletePayment-${user?.id}`, (newToken: string) => {
      if (newToken === 'unauthorized') return
      if (newToken.length === 6) {
        // Dividir el string en un array de caracteres
        const tokenArray = newToken.split('')
        setValuesOTP(tokenArray)
        onCompleteOTG(newToken)
      }
    })

    return () => {
      socketWhats.disconnect()
    }
  }, [])

  useEffect(() => {
    if (!isWaiting) return

    const interval = setTimeout(() => {
      setIsWaiting(false)
    }, 300000) //  5minutos

    return () => clearInterval(interval)
  }, [isWaiting])

  const SendToken = async () => {
    startLoading()

    if (!formState || formState.length < 10) {
      setError(true)
      stopLoading()
      return
    } else {
      setError(false)
      try {
        await GetTokentForDelete(paymentDetaild.id, formState)
        setIsWaiting(true)
        setisopenModal(true)
      } catch (error) {
        console.error(error)
      } finally {
        stopLoading()
      }
    }
  }

  const closeModal = () => {
    setisopenModal(false)
    setIsTokenError(false)
  }

  const onCompleteOTG = async (tokentString: string) => {
    startLoading()

    if (tokentString.length < 6) {
      setErrorOTP(true)
      stopLoading()
      return
    } else {
      setErrorOTP(false)
      try {
        const success = await verifyAndDelete(tokentString, paymentDetaild.id)
        if (!success) return setIsTokenError(true)

        closeModal()
        await new Promise(resolve => setTimeout(resolve, 2500)) //2.5 Segundos
        onClose()
      } catch (error) {
        console.error('token no valido')
      } finally {
        stopLoading()
      }
    }
  }

  return (
    <div className="deleteConceptContainer ">
      <p>{title}</p>
      <p className="sub-title">{`${paymentDetaild.type} ${paymentDetaild.name} ${
        paymentDetaild.date
      } Q${paymentDetaild.price.toFixed(2)}`}</p>

      <LabelANTD
        borderColor
        value={formState}
        onChange={setFormState}
        error={error}
        disabled={isWaiting}
        labelError={'Se requiere minimo 10 caracteres'}
      />

      <p>{`Tu representante corporativo será notificado, y solamente él podrá darte el token de autorización.`}</p>
      <div className="buttonContainer">
        <div
          className={`button ${
            isWaiting ? 'borderAnimateIpassDisable disable' : 'borderAnimateIpass'
          } `}
          onClick={isWaiting ? undefined : SendToken}
        >
          <div className="inner">ENVIAR TOKEN</div>
        </div>
      </div>
      <InputOTP
        label="Ingresar token"
        labelError={'Llene todas las casillas'}
        error={errorOTP}
        values={valuesOTP}
        setValues={setValuesOTP}
        onComplete={onCompleteOTG}
        borderColor
      />

      <Modal
        isOpen={isopenModal}
        borderColor={true}
        onClose={closeModal}
        styleContainerContent={{ padding: '5px', margin: '0px' }}
        styleContainer={{
          width: '400px',
          height: '230px',
          padding: '0px',
          overflow: 'hidden',
          margin: '5px',
          borderRadius: '20px'
        }}
        styleClose={{ fontSize: '20px', right: ' 10px', top: '10px' }}
      >
        <MiniLoadingToken onClose={onClose} />
      </Modal>

      <Modal
        isOpen={isTokenError}
        borderColor={true}
        onClose={closeModal}
        styleContainerContent={{ padding: '5px', margin: '0px' }}
        styleContainer={{
          width: '370px',
          height: '230px',
          padding: '0px',
          overflow: 'hidden',
          margin: '5px',
          borderRadius: '20px'
        }}
        styleClose={{ fontSize: '20px', right: ' 10px', top: '10px' }}
      >
        <TokenErrorModal onClose={onClose} />
      </Modal>
    </div>
  )
}
