import React, { FC, useEffect, useState } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { Pagination } from '../../../types/types'
import Table, { TableColumn, enumTypeColumn } from '../../Table'
import { Notifications, NotificationsOff } from '@mui/icons-material'
import { NotificationResident } from '../../../services/delegateResident'

import Modal from '../../GeneralComponents/Modal'

import {
  deleteDelegate,
  enableDelegate,
  listDelegateByResident,
  regenerateCodeDelegate
} from '../../../services/delegateResident'
import { DelegateResident } from '../../../types/delegate_resident'
import DeleteButton from '../../Table/components/DeleteButton'
import ViewQr from '../../ViewQr'
import { columnDelegateResident } from './Columns'
import FormDelegateResident from './Form'
import { useAuth } from '../../../Provider/AuthProvider'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton } from '@mui/material'
import EnableComponentDelegate from '../../../components/EnableComponentDelegate'

const DelegatedResident: FC<{ id: string; maxAmount: number }> = ({
  id,
  maxAmount
}) => {
  const [data, setData] = useState<Pagination<DelegateResident>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const limit = 6
  const { user } = useAuth()
  const [search, setSearch] = useState('')
  const { startLoading, stopLoading } = useLoading()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<DelegateResident | undefined>(
    undefined
  )

  useEffect(() => {
    getData('', 1)
  }, [])

  const onCloseModal = () => {
    setOpen(false)
  }
  const handleOpenModal = () => {
    if (data.data.length < maxAmount) {
      setOpen(true)
    }
  }
  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteDelegate(id)
      getData(search.length > 3 ? search : '', data.currentPage)
      stopLoading()
    } catch (error) {
      console.error(error)
    }
  }
  const handleView = (value: DelegateResident) => {
    setSelected(value)
  }

  const toggleNotification = async (
    residentId: string,
    notification: boolean
  ) => {
    try {
      startLoading()
      await NotificationResident(residentId, !notification)
      await getData(search.length > 3 ? search : '', data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const Actions = ({ id, item }: { id: string; item: DelegateResident }) => {
    return (
      <div className='actionsContainer'>
        <div
          onClick={() => toggleNotification(item.id, item.enableNotification)}>
          {item.enableNotification ? (
            <Notifications style={{ color: 'green' }} />
          ) : (
            <NotificationsOff style={{ color: 'red' }} />
          )}
        </div>
        <ViewQr
          regenerateCode={(id) => regenerateCodeDelegate(id)}
          onSubmit={() =>
            getData(search.length > 3 ? search : '', data.currentPage)
          }
          item={item}
          text={{
            names: `${item.name} ${item.lastName}`,
            name: item.name,
            lastName: item.lastName,
            clientName: `${user?.client?.name}`,
            type: 'RD',
            destine: item.resident?.destinationCode || ''
          }}
        />
        <IconButton onClick={() => handleView(item as DelegateResident)}>
          <VisibilityIcon style={{ color: 'white' }} />
        </IconButton>
        <DeleteButton OnDelete={() => handleDelete(id)} />
      </div>
    )
  }
  const handlePageChange = (newPage: number) => {
    getData(search.length >= 3 ? search : '', newPage)
  }
  const getData = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const delegateResident = await listDelegateByResident({
        id,
        params: { page, search: searchValue, limit }
      })
      setData(delegateResident)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const handleSubmit = () => {
    setOpen(false)
    getData(search.length > 3 ? search : '', data.currentPage)
  }
  useEffect(() => {
    if (selected) {
      setOpen(true)
    }
  }, [selected])

  const handleChangeEnable = async ({
    Resident,
    Guest,
    id
  }: {
    Resident: boolean
    Guest: boolean

    id: string
  }) => {
    try {
      startLoading()
      await enableDelegate(id, { enable: Resident, guest: Guest })
      getData(search.length > 3 ? search : '', data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const columnsResidentEnable: TableColumn[] = [
    ...columnDelegateResident,
    {
      title: 'Habilitado',
      dataIndex: 'enable',
      key: 'enable',
      type: enumTypeColumn.custom,
      CustomRender: ({ id, item }: { id: string; item?: DelegateResident }) => {
        if (!item) return null
        return (
          <div className='enableContainer'>
            <EnableComponentDelegate
              enable={item.enable}
              guest={item.enableGuest}
              onUpdate={({ Resident, Guest }) =>
                handleChangeEnable({ Resident, Guest, id })
              }
            />
            <span className={`${item.enableGuest ? 'green' : 'red'}`}> I</span>
          </div>
        )
      }
    }
  ]
  return (
    <>
      <div className='title'>
        <h2>{`Residentes Delegados ${data.data.length}/${maxAmount}`}</h2>
      </div>
      <Table
        onPageChange={handlePageChange}
        onChangeSearch={setSearch}
        pagination={data}
        handleOpenModal={handleOpenModal}
        columns={columnsResidentEnable}
        Actions={Actions as any}
        itemsPerPage={limit}
      />
      <Modal
        styleContainer={{ width: '900px' }}
        isOpen={open}
        onClose={onCloseModal}>
        <FormDelegateResident
          initialValue={selected}
          residentId={id}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  )
}

export default DelegatedResident
