import React from 'react'
import './style.scss'

export interface ReportModalProps {
  onClose: () => void
}

export const TokenErrorModal: React.FC<ReportModalProps> = ({ onClose }) => {
  return (
    <div className="miniDeleteLoading">
      <>
        <h3>LO LAMENTO</h3>
        <p>{`El TOKEN ingresado no corresponde con la transacción deseada.`}</p>
        <p>{`Pide uno nuevo o comunícate con tu corporativo para solicitarle el token original.`}</p>
        <div className="buttonContainer">
          <div className={`button ${'borderAnimateIpass'} `} onClick={onClose}>
            <div className="inner">ENTENDIDO</div>
          </div>
        </div>
      </>
    </div>
  )
}
