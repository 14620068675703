import { ConfigProvider, Input } from 'antd'
import React, { useEffect } from 'react'

interface LabelProps {
  value: string
  onChange: (event: any) => void
  borderColor?: boolean
  labelError?: string
  error?: boolean
  disabled?: boolean
}

export const LabelANTD: React.FC<LabelProps> = ({
  onChange,
  value,
  borderColor,
  labelError,
  error,
  disabled
}) => {
  useEffect(() => {}, [labelError, error])

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorBgContainer: 'rgba(56, 78, 183, 0.3)',
            colorBorder: '#484747',
            colorPrimary: 'white',
            hoverBorderColor: 'white',
            colorText: 'white',
            colorTextBase: 'white',
            optionSelectedColor: 'white',
            selectorBg: 'rgba(56, 78, 183, 0.3)',
            optionSelectedBg: 'rgba(56, 78, 183, 0.3)',
            multipleItemBg: 'rgba(56, 78, 183, 0.3)',
            colorIcon: 'white',
            colorIconHover: 'white'
          }
        },
        token: {
          colorBgBase: 'rgba(56, 78, 183, 0.3)',
          colorBgContainer: 'rgba(56, 78, 183, 0.3)',
          colorText: 'white',
          colorTextPlaceholder: 'white',
          colorIcon: 'white',
          colorIconHover: 'white'
        }
      }}
    >
      <div
        style={{ width: '-webkit-fill-available' }}
        className={`${
          borderColor
            ? error
              ? 'borderAnimateIpassError border'
              : disabled
              ? 'borderAnimateIpassDisable border'
              : 'borderAnimateIpass border'
            : ''
        } `}
      >
        <Input
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value)
          }}
          disabled={disabled}
          value={value}
          prefix="MOTIVO:"
          size="middle"
          variant="borderless"
          className={`${borderColor ? 'inner' : ''}`}
        />
      </div>
      {labelError && error && (
        <p style={{ color: 'red', margin: '0px', fontSize: '14px', marginTop: '-15px' }}>
          {labelError}
        </p>
      )}
    </ConfigProvider>
  )
}
