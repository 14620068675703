import { SelectOption } from '../components/Form/Select'
import { PaymentConceptResidentCreate } from '../types/Payments'
import axiosInstance from './axiosInstance'
import axiosWhatsApp from './axiosInstanceWAPP'

export const getConcept = async (
  residentId: string,
  clientId: string
): Promise<SelectOption[]> => {
  try {
    const response = await axiosInstance.get(`/api/conceptsResident/${residentId}/${clientId}`)

    return response.data
  } catch (error) {
    throw error
  }
}

export const newCuota = async (
  params: PaymentConceptResidentCreate
): Promise<PaymentConceptResidentCreate> => {
  try {
    const response = await axiosInstance.post(
      `/api/conceptsResident`,
      JSON.stringify(params),
      {}
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export const updateCuota = async (
  params: PaymentConceptResidentCreate,
  conceptId: string
): Promise<PaymentConceptResidentCreate> => {
  try {
    const response = await axiosInstance.put(
      `/api/conceptsResident/${conceptId}`,
      JSON.stringify(params),
      {}
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export const statusConceptResident = async (
  id: string,
  isGlobal: boolean,
  residentId: string
) => {
  try {
    await axiosInstance.patch(`/api/conceptsResident/status/`, { id, isGlobal, residentId })
  } catch (error) {
    throw error
  }
}

export const deleteConceptResident = async (id: string) => {
  try {
    await axiosInstance.delete(`/api/conceptsResident/${id}`)
  } catch (error) {
    throw error
  }
}

export const GetTokentForDelete = async (paymentId: string, concept: string) => {
  try {
    await axiosWhatsApp.post(`api/token/delete_payment`, { concept, paymentId })
  } catch (error) {
    throw error
  }
}

export const verifyAndDelete = async (tokenForDelete: string, paymentId: string) => {
  try {
    const response = await axiosInstance.delete(
      `api/conceptsResident/delete_payment_detail/${tokenForDelete}/${paymentId}`
    )
    return response.data.success as boolean
  } catch (error) {
    throw error
  }
}
