import React from 'react'
import { Input } from 'antd'

interface InputOTPProps {
  length?: number
  onComplete: (value: string) => void
  values: string[]
  setValues: React.Dispatch<React.SetStateAction<string[]>>
  label: string
  borderColor?: boolean
  labelError?: string
  error?: boolean
}

export const InputOTP: React.FC<InputOTPProps> = ({
  length = 6,
  label,
  onComplete,
  values,
  setValues,
  borderColor,
  labelError,
  error
}) => {
  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    const index = Array.from(target.parentNode!.children).indexOf(target)
    const newValue = target.value

    // Validar solo números
    if (/^\d$/.test(newValue)) {
      const updatedValues = [...values]
      updatedValues[index] = newValue
      setValues(updatedValues)

      // Mover el foco al siguiente input automáticamente
      if (index < length - 1) {
        const nextInput = target.nextElementSibling as HTMLInputElement | null
        nextInput?.focus()
      }

      // Verificar si todos los campos están completos
      if (updatedValues.every(val => val !== '') && onComplete) {
        onComplete(updatedValues.join(''))
      }
    } else if (newValue === '') {
      // Si se elimina el valor, vaciar el campo actual
      const updatedValues = [...values]
      updatedValues[index] = ''
      setValues(updatedValues)
    } else {
      // Si el valor no es válido, restablecer el input
      target.value = ''
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    const index = Array.from(target.parentNode!.children).indexOf(target)

    switch (e.key) {
      case 'Backspace':
        if (values[index] === '') {
          // Mover al input anterior si está vacío
          if (index > 0) {
            const prevInput = target.previousElementSibling as HTMLInputElement | null
            prevInput?.focus()
          }
        }
        break

      case 'Delete':
        // Eliminar el valor actual y mantener el foco
        const updatedValues = [...values]
        updatedValues[index] = ''
        setValues(updatedValues)
        break

      case 'ArrowLeft':
        // Mover al input anterior
        if (index > 0) {
          const prevInput = target.previousElementSibling as HTMLInputElement | null
          prevInput?.focus()
        }
        break

      case 'ArrowRight':
        // Mover al input siguiente
        if (index < length - 1) {
          const nextInput = target.nextElementSibling as HTMLInputElement | null
          nextInput?.focus()
        }
        break

      case 'Enter':
        // Ejecutar onComplete si todos los campos están llenos
        onComplete(values.join(''))

        break

      default:
        break
    }
  }

  return (
    <>
      {' '}
      {label && (
        <p style={{ color: 'white', margin: '0px', fontSize: '18px', }}>
          {label}
        </p>
      )}
      <div
        className={`${
          borderColor
            ? error
              ? 'borderAnimateIpassError border'
              : 'borderAnimateIpass border'
            : ''
        } `}
        onInput={handleInput}
      >
        <div style={{ padding: '0px 5px 5px' }} className={`${borderColor ? 'inner' : ''}`}>
          {Array.from({ length }, (_, index) => (
            <Input
              key={index}
              maxLength={1}
              variant="borderless"
              value={values[index]}
              onChange={() => {}} // Evitar warnings
              onKeyDown={handleKeyDown}
              style={{
                color: 'white',
                width: '1rem',
                padding: '0px',
                borderRadius: '0px',
                borderBottom: '1px solid #ffffff',
                textAlign: 'center',
                margin: '0 0.5rem'
              }}
            />
          ))}
        </div>
      </div>
      {labelError && error && (
        <p style={{ color: 'red', margin: '0px', fontSize: '14px', marginTop: '-15px' }}>
          {labelError}
        </p>
      )}
    </>
  )
}
