import React, { ReactNode } from 'react'
import Sidebar from '../Sidebar'
import './styles.scss'
import UserInfo from '../UserInfo'
import { navBarStore } from '../../../services/store'

interface LayoutProps {
  children: ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { statusBar } = navBarStore()

  return (
    <div className="layoutContainer">
      <nav>
        <Sidebar />
      </nav>
      <UserInfo />
      <main id="main" className={statusBar ? `fullMainContainer` : `mainContainer`}>
        {children}
      </main>
    </div>
  )
}

export default Layout
