// AuthContext.tsx
import React, { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { loginService, renewToken, validateToken } from '../services/login'
import Cookies from 'js-cookie'
import { IUser } from '../types/user'
import { useLoading } from './LoadingProvider'
import { useNavigate } from 'react-router-dom'

export type loginType = {
  userName: string
  password: string
  role: string
}

type AuthContextType = {
  user: IUser | null
  login: (props: loginType) => void
  token: string
  logout: () => void
  refreshToken: () => void
  initial: () => Promise<void>
  loadingUser: boolean
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth debe ser utilizado dentro de un AuthProvider')
  }
  return context
}

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null)
  const [token, setToken] = useState<string>('')
  const [loadingUser, setLoadingUser] = useState(true)
  const { startLoading, stopLoading, isElectron, showMessage } = useLoading()
  const navigate = useNavigate()
  const initial = async () => {
    try {
      let myToken = ''
      if (!isElectron) {
        myToken = Cookies.get('token') || ''
      } else {
        myToken = token
      }
      if (myToken) {
        setLoadingUser(true)
        setToken(myToken)
        const user = await validateToken(myToken)
        setUser(user)
      }
      setLoadingUser(false)
    } catch (err) {
      console.error(err)
      logout()
    }
  }
  useEffect(() => {
    if (!isElectron) {
      initial()
    }
    const ipcRenderer = (window as any).ipcRenderer
    ipcRenderer?.on('token-saved', (_: any) => {
      navigateElectron('checkin.html')
    })

    ipcRenderer?.send('get-store')

    ipcRenderer?.on('token-retrieved', (_: any, retrievedToken: any) => {
      setToken(retrievedToken)
      setLoadingUser(false)
    })

    // Limpiar efectos cuando el componente se desmonta
    return () => {
      ipcRenderer?.removeAllListeners('token-retrieved')
      ipcRenderer?.removeAllListeners('token-saved')
    }
  }, [])

  useEffect(() => {
    if (!user && token) {
      initial()
    }
  }, [token])

  const navigateElectron = (page: string) => {
    const ipcRenderer = (window as any).ipcRenderer
    ipcRenderer.send('open-window', page)
  }

  const saveValue = (token: string) => {
    const ipcRenderer = (window as any).ipcRenderer
    ipcRenderer.send('save-store', token)
  }

  const login = async (params: loginType) => {
    try {
      startLoading()
      setLoadingUser(true)
      const { token } = await loginService(params)

      if (isElectron) {
        saveValue(token)
      } else {
        Cookies.set('token', token, {
          expires: 1, // Corrección en la expiración
          path: '/'
        })
      }

      setToken(token)

      if (!isElectron) {
        await initial()
        navigate('/')
      }
    } catch (error) {
      console.error(error)
      showMessage('error', (error as any)?.response?.data?.message)
    } finally {
      setLoadingUser(false)
      stopLoading()
    }
  }

  const logout = () => {
    setToken('')
    setUser(null)
    if (isElectron) {
      const ipcRenderer = (window as any).ipcRenderer
      ipcRenderer.send('clear-store')
      navigateElectron('index.html')
    } else {
      Cookies.remove('token', { path: '/' })
      navigate('/login')
    }
  }

  const refreshToken = async () => {
    try {
      // Llamamos a la función que entrega el nuevo token
      const newToken = await renewToken(token)

      if (!newToken) {
        throw new Error('No se pudo obtener un nuevo token')
      }

      // Guardamos el nuevo token en cookies o en almacenamiento, dependiendo del entorno
      if (isElectron) {
        saveValue(newToken)
      } else {
        Cookies.set('token', newToken, {
          expires: 1, // Define la expiración del token
          path: '/'
        })
      }

      // Actualizamos el estado del token
      setToken(newToken)

      // Opcional: Actualiza la información del usuario si es necesario
      await initial()

      console.log('Token renovado exitosamente')
    } catch (error) {
      console.error('Error al renovar el token:', error)
      logout()
    }
  }
  if (loadingUser) return <></>
  return (
    <AuthContext.Provider
      value={{ user, initial, token, loadingUser, login, logout, refreshToken }}
    >
      {children}
    </AuthContext.Provider>
  )
}
