import React, { FC, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import PopUpConfirm from '../PopupConfirm'

export interface DeleteButtonType {
  disabled?: boolean
  OnDelete: () => void
  title?: string
  description?: string

  acceptText?: string
  rejectText?: string
}

const DeleteButton: FC<DeleteButtonType> = ({
  disabled,
  OnDelete,
  title,
  description,
  acceptText,
  rejectText
}) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)

  const handleAccept = () => {
    setIsPopUpOpen(false)
    OnDelete()
  }

  const handleReject = () => {
    setIsPopUpOpen(false)
  }

  const disabledButtonStyles = {
    color: 'gray',
    pointerEvents: 'none'
  }

  const enabledButtonStyles = {
    color: 'tomato'
  }

  return (
    <div>
      <IconButton
        onClick={() => setIsPopUpOpen(true)}
        disabled={disabled}
        style={disabled ? disabledButtonStyles : enabledButtonStyles}
      >
        <DeleteIcon />
      </IconButton>

      <PopUpConfirm
        isOpen={isPopUpOpen}
        onClose={() => setIsPopUpOpen(false)}
        title={title ? title : '¿Estás seguro de eliminar?'}
        description={description ? description : 'Esta acción no se puede deshacer.'}
        acceptText={acceptText ? acceptText : 'Aceptar'}
        rejectText={rejectText ? rejectText : 'Cancelar'}
        handleAccept={handleAccept}
        handleReject={handleReject}
      />
    </div>
  )
}

export default DeleteButton
